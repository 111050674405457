<template>
  <div class="tw-flex" style="height: 100vh">
    <div class="tw-w-[200px] tw-h-full" style="overflow-y: scroll">
      <div class="tw-pl-16px tw-pt-[10px]">
        <h3>组件总览</h3>
      </div>
      <divider />
      <div v-for="item in data.componentList" :key="item" class="tw-pl-16px tw-py-[8px]" style="cursor: pointer">
        <h4>{{ item.name }}</h4>
      </div>
    </div>
    <div class="padding-20">
      <h3>带下载链接的按钮</h3>
      <category-picker :init-data="data.items" style="width: 200px" />
      <br />
      {{ data.area }}<br />
      <area-picker v-model="data.area" />
      <br />
      {{ data.address }}<br />
      <address-input v-model="data.address" />
      <br />
      {{ data.richText }}<br />
      <!--    <breadcrumb :routes="data.routes" />-->
      <br />
      <category-select :init-data="data.items" />
      <br />
      <divider />
      <br />
      <a-button type="primary" @click="data.showImportResultModal = true">导出结果弹窗</a-button>

      <import-result-modal
        v-model="data.showImportResultModal"
        :visible="data.showImportResultModal"
        :import-result="data.importResult"
      />
      <br />
      <div class="tw-flex tw-flex-1"><pagination :total="45" size="large" /></div>
      <br />
      <router-link-button size="large" type="danger" :to="{ name: '' }">aaa</router-link-button>
      <br />
      <upload-button />
      <br />
      <upload-image />
      <br />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const requireComponent = require.context('@/common/components', true, /\.vue$/)

const data = ref({
  componentList: [],
  items: [
    {
      id: 540,
      typeName: '清热解毒',
      orderBy: 20,
      enableFlag: true,
      productTypeQueries: [
        { typeName: '清热解毒' },
        { typeName: '咽喉肿痛' },
        { typeName: '口腔溃疡' },
        { typeName: '牙龈肿痛' },
        { typeName: '肝胆湿热' },
        { typeName: '清热泻火' },
      ],
    },
    {
      id: 553,
      typeName: '咳嗽用药',
      orderBy: 19,
      enableFlag: true,
      productTypeQueries: [
        { typeName: '咽炎' },
        { typeName: '气管炎' },
        { typeName: '支气管炎' },
        { typeName: '哮喘' },
        { typeName: '止咳化痰' },
      ],
    },
    {
      id: 569,
      typeName: '皮肤用药',
      orderBy: 18,
      enableFlag: true,
      productTypeQueries: [
        { typeName: '过敏性皮炎' },
        { typeName: '牛皮鲜' },
        { typeName: '荨麻疹' },
        { typeName: '烧烫伤' },
        { typeName: '头皮屑' },
        { typeName: '毛囊炎' },
        { typeName: '脂溢性皮炎' },
        { typeName: '腋臭' },
        { typeName: '脚气' },
      ],
    },
    {
      id: 579,
      typeName: '肠胃用药',
      orderBy: 17,
      enableFlag: true,
      productTypeQueries: [
        { typeName: '胃溃疡' },
        { typeName: '十二指肠溃疡' },
        { typeName: '消化不良' },
        { typeName: '痔疮' },
        { typeName: '胃炎' },
        { typeName: '结肠炎' },
        { typeName: '便秘' },
        { typeName: '胃痉挛' },
      ],
    },
    {
      id: 588,
      typeName: '风湿骨伤',
      orderBy: 16,
      enableFlag: true,
      productTypeQueries: [
        { typeName: '类风湿关节炎' },
        { typeName: '风湿性关节炎' },
        { typeName: '痛风' },
        { typeName: '铁打损伤' },
      ],
    },
    {
      id: 593,
      typeName: '男科用药',
      orderBy: 15,
      enableFlag: true,
      productTypeQueries: [
        { typeName: '阳痿早泄' },
        { typeName: '前列腺炎' },
        { typeName: '前列腺增生' },
        { typeName: '其他' },
      ],
    },
    {
      id: 598,
      typeName: '三高用药',
      orderBy: 14,
      enableFlag: true,
      productTypeQueries: [
        { typeName: '高血压' },
        { typeName: '冠心病' },
        { typeName: '心绞痛' },
        { typeName: '高血脂' },
        { typeName: '糖尿病' },
        { typeName: '动脉硬化' },
        { typeName: '心肌梗死' },
        { typeName: '脑血管痉挛' },
      ],
    },
    {
      id: 607,
      typeName: '儿童用药',
      orderBy: 13,
      enableFlag: true,
      productTypeQueries: [
        { typeName: '儿童感冒' },
        { typeName: '儿童腹泻' },
        { typeName: '儿童消化不良' },
        { typeName: '儿童补益' },
        { typeName: '儿童咳嗽' },
        { typeName: '小儿退热贴' },
      ],
    },
    {
      id: 614,
      typeName: '性福生活',
      orderBy: 12,
      enableFlag: true,
      productTypeQueries: [
        { typeName: '避孕套' },
        { typeName: '避孕药' },
        { typeName: '润滑液' },
        { typeName: '情趣玩具' },
        { typeName: '测卵' },
        { typeName: '早早孕' },
      ],
    },
    {
      id: 621,
      typeName: '妇科用药',
      orderBy: 11,
      enableFlag: true,
      productTypeQueries: [
        { typeName: '阴道炎' },
        { typeName: '盆腔炎' },
        { typeName: '附件炎' },
        { typeName: '其他' },
      ],
    },
    {
      id: 634,
      typeName: '家庭常备',
      orderBy: 10,
      enableFlag: true,
      productTypeQueries: [
        { typeName: '消化不良' },
        { typeName: '小儿感冒' },
        { typeName: '蚊虫叮咬' },
        { typeName: '心绞痛' },
        { typeName: '跌打损伤' },
        { typeName: '创可贴' },
        { typeName: '口腔溃疡' },
      ],
    },
    {
      id: 642,
      typeName: '五官用药',
      orderBy: 9,
      enableFlag: true,
      productTypeQueries: [{ typeName: '眼科用药' }, { typeName: '耳鼻喉药' }],
    },
    {
      id: 645,
      typeName: '养生花茶',
      orderBy: 8,
      enableFlag: true,
      productTypeQueries: [
        { typeName: '养生花茶' },
        { typeName: '枸杞子' },
        { typeName: '菊花' },
        { typeName: '金银花' },
        { typeName: '甘草' },
        { typeName: '其他' },
      ],
    },
    {
      id: 657,
      typeName: '美颜瘦身',
      orderBy: 7,
      enableFlag: true,
      productTypeQueries: [{ typeName: '减肥' }, { typeName: '燕窝' }, { typeName: '面膜' }, { typeName: '其他' }],
    },
    {
      id: 662,
      typeName: '滋补调养',
      orderBy: 6,
      enableFlag: true,
      productTypeQueries: [
        { typeName: '肾阴虚' },
        { typeName: '肾阳虚' },
        { typeName: '气血亏虚' },
        { typeName: '阿胶' },
        { typeName: '肾宝片' },
      ],
    },
    {
      id: 675,
      typeName: '维矿补益',
      orderBy: 5,
      enableFlag: true,
      productTypeQueries: [
        { typeName: '补钙' },
        { typeName: '贫血' },
        { typeName: '抗疲劳' },
        { typeName: '补锌' },
        { typeName: '补铁' },
        { typeName: '维生素' },
      ],
    },
    {
      id: 682,
      typeName: '医疗器械',
      orderBy: 3,
      enableFlag: true,
      productTypeQueries: [
        { typeName: '口罩' },
        { typeName: '绷带 纱布 棉签' },
        { typeName: '酒精 消毒剂' },
        { typeName: '创可贴 胶布' },
        { typeName: '血压计' },
        { typeName: '血糖仪' },
        { typeName: '退热贴' },
      ],
    },
    {
      id: 690,
      typeName: '个人护理',
      orderBy: 2,
      enableFlag: true,
      productTypeQueries: [
        { typeName: '护垫' },
        { typeName: '卫生巾' },
        { typeName: '花露水' },
        { typeName: '洗液' },
        { typeName: '护眼贴' },
      ],
    },
    {
      id: 696,
      typeName: '重楼',
      orderBy: 9999,
      enableFlag: true,
      productTypeQueries: [{ typeName: '风寒感冒' }],
    },
  ],
  area: ['天津市', '河北区'],
  address: { provinceName: '内蒙古自治区', cityName: '通辽市', areaName: '库伦旗', street: '测试测试' },
  routes: [
    { name: 'aaa', meta: { title: 'aaa' } },
    { name: 'bbb', meta: { title: 'bbb' } },
    {
      name: 'ccc',
      meta: { title: 'ccc' },
    },
  ],
  showImportResultModal: false,
  importResult: {
    success: 20,
    failure: 3,
    total: 23,
    errorFile: 'aaaa.xlsx',
    errorTable: {
      // 显示错误数据表格
      columnKey: 'row',
      width: 600, //表格宽度
      height: 250, //表格高度
      errorTableColumns: [
        {
          title: '行数',
          dataIndex: 'row',
        },
        {
          title: '条形码',
          dataIndex: 'barCode',
        },
        {
          title: '错误详情',
          dataIndex: 'error',
        },
      ],
      data: [
        { row: 1, barCode: 1234, error: 'abcd' },
        { row: 2, barCode: 1234, error: 'abcd' },
        { row: 3, barCode: 1234, error: 'abcd' },
        { row: 4, barCode: 1234, error: 'abcd' },
        { row: 5, barCode: 1234, error: 'abcd' },
        { row: 6, barCode: 1234, error: 'abcd' },
        { row: 7, barCode: 1234, error: 'abcd' },
        { row: 8, barCode: 1234, error: 'abcd' },
        { row: 9, barCode: 1234, error: 'abcd' },
        { row: 10, barCode: 1234, error: 'abcd' },
        { row: 11, barCode: 1234, error: 'abcd' },
        { row: 12, barCode: 1234, error: 'abcd' },
        { row: 13, barCode: 1234, error: 'abcd' },
        { row: 14, barCode: 1234, error: 'abcd' },
      ],
    },
  },
  richText: 'aaabbbc<b>cc</b>dddeee',
})

const initComponentList = () => {
  requireComponent.keys().forEach(filePath => {
    const pathList = filePath.split('/')
    // 过滤掉components目录下的一级文件注册，如demo.vue
    if (pathList.length > 2) {
      const fileName = pathList[filePath.endsWith('index.vue') ? pathList.length - 2 : pathList.length - 1] // 如果组件名是index，取目录为组件名称
      let componentName = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')
      data.value.componentList.push({ name: componentName })
    }
  })
}

initComponentList()
</script>

<style scoped lang="less">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .loading-layer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0);
    z-index: 99999;

    img {
      border-radius: 5px;
      padding: 10px;
      background: #fff;
    }
  }
}
</style>
