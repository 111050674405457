<template>
  <div
    class="divider"
    :style="{
      borderColor: color,
      borderWidth: toPxString(width),
      marginTop: toPxString(marginTop),
      marginBottom: toPxString(marginBottom),
    }"
  ></div>
</template>

<script>
import { Divider } from 'ant-design-vue'
export default {
  name: 'Divider',
  components: {
    [Divider.name]: Divider,
  },
  props: {
    marginTop: {
      type: [String, Number],
      default() {
        return '10px'
      },
    },
    marginBottom: {
      type: [String, Number],
      default() {
        return '10px'
      },
    },
    color: {
      type: String,
      default() {
        return 'rgba(235,237,240,1)'
      },
    },
    width: {
      type: [String, Number],
      default() {
        return '1px'
      },
    },
  },
  methods: {
    toPxString(value) {
      return (value + 'px').replace(/px\s*px/, 'px')
    },
  },
}
</script>

<style lang="less" scoped>
.divider {
  height: 0;
  border-top: solid 1px rgba(235, 237, 240, 1);
}
</style>
