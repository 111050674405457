<template>
  <a-cascader
    v-model:value="tmpValue"
    class="area-picker"
    :field-names="{ label: 'label', value: 'label', children: 'children' }"
    :options="areaData"
    :size="size"
    :get-popup-container="trigger => trigger.parentNode"
    :placeholder="placeholder"
    :show-search="showSearch"
    @change="onChange"
    @blur="onFieldBlur"
  />
</template>

<script>
import { Cascader, Form } from 'ant-design-vue'
import areaData from '../../assets/areaData.json'

export default {
  name: 'AreaPicker',
  components: {
    [Cascader.name]: Cascader,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    showSearch: {
      type: Boolean,
      default() {
        return false
      },
    },
    size: {
      type: String,
      default() {
        return 'default'
      },
    },
  },
  emits: ['update:modelValue', 'change'],
  setup() {
    const { onFieldChange, onFieldBlur } = Form.useInjectFormItemContext()
    return { onFieldChange, onFieldBlur }
  },
  data() {
    return {
      areaData,
      tmpValue: [],
    }
  },
  watch: {
    modelValue(val) {
      this.tmpValue = val
    },
  },
  mounted() {
    this.tmpValue = this.modelValue
  },
  methods: {
    onChange(value, selectedOptions) {
      this.$emit('update:modelValue', this.tmpValue)
      this.$emit('change', value, selectedOptions)
      this.onFieldChange()
    },
  },
}
</script>
