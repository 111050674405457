import Antd, { message } from 'ant-design-vue'
import numeral from 'numeral'
import dayjs from 'dayjs'
import numberFormatter from '@/common/utils/numberFormatter'
import dateTimeFormatter from '@/common/utils/dateTimeFormatter'
import { createOpenModalFunction } from '@/common/utils/openModal'
import customComponents from '@/common/components'

/**
 * 将store/router/组件/自定义组件注入app
 * @param store
 * @param router
 * @return {(function(*): void)|*}
 */
const importGlobal = (store, router) => app => {
  // 全局注入
  app.use(store).use(router).use(Antd).use(customComponents)
  app.config.globalProperties.$message = message
  app.config.globalProperties.numeral = numeral
  app.config.globalProperties.$dayjs = dayjs
  app.config.globalProperties.productionTip = false
  app.config.globalProperties.numberFormatter = numberFormatter
  app.config.globalProperties.dateTimeFormatter = dateTimeFormatter

  app.config.globalProperties.openModal = createOpenModalFunction(importGlobal(store, router))
}

/**
 * 初始化app
 * @param app
 * @param store
 * @param router
 */
const initAppConfig = (app, store, router) => {
  importGlobal(store, router)(app)
}

export default initAppConfig
