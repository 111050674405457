<template>
  <a-modal v-model:visible="modalVisible" :title="title" centered @ok="handleOk">
    <div>
      <p>{{ info }}</p>
    </div>
    <template #footer>
      <a-button type="primary" @click="handleOk">知道了</a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    info: { type: String, default: '' },
  },

  emits: ['clickOk'],

  data() {
    return {
      modalVisible: true,
    }
  },

  methods: {
    handleOk() {
      this.modalVisible = false
      this.$emit('clickOk')
    },
  },
}
</script>
