import dayjs from 'dayjs'

/**
 * @method: 格式化时间
 * @value: 时间戳或者时间文本
 * @format: 要转化的格式
 * @oriFormat: 如果是时间文本，原时间格式
 * @return: 格式化文本
 */
export default function dateTimeFormatter(value, format = 'YYYY-MM-DD', oriFormat) {
  if (typeof value === 'string') {
    return dayjs(value, oriFormat).format(format)
  } else if (typeof value === 'number') {
    return dayjs(value).format(format)
  }
  return ''
}
