<template>
  <a v-if="disabled" class="ant-btn" :class="['ant-btn-' + sizeMap[size], 'ant-btn-' + typeMap[type]]" disabled>
    <slot></slot>
  </a>
  <a
    v-else
    class="ant-btn"
    :class="['ant-btn-' + sizeMap[size], 'ant-btn-' + typeMap[type]]"
    :href="href"
    :target="target"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'ALink',
  props: {
    url: {
      type: String,
      default: '',
    },
    query: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: '_self',
    },
  },
  data() {
    return {
      sizeMap: {
        small: 'sm',
        large: 'lg',
      },
      typeMap: {
        primary: 'primary',
        dashed: 'dashed',
        danger: 'danger',
        link: 'link',
      },
    }
  },
  computed: {
    href() {
      if (this.disabled) {
        return 'javascript:void(0)'
      }
      if (/^(http|https|ftp):\/\//.test(this.url)) {
        return this.url + (this.query ? '?' + new URLSearchParams(this.query).toString() : '')
      } else {
        return (
          process.env.BASE_URL +
          process.env.PROJECT_BASE +
          this.url +
          (this.query ? '?' + new URLSearchParams(this.query).toString() : '')
        )
      }
    },
  },
}
</script>

<style lang="less" scoped></style>
