import zh_CN from 'ant-design-vue/es/locale-provider/zh_CN'
import { createApp, h } from 'vue'
import { ConfigProvider } from 'ant-design-vue'

/**
 * 打开modal
 * @param initFn {Function} 初始化Vue实例的方法
 */
export function createOpenModalFunction(initFn) {
  /**
   * 打开modal
   * @param component {Component} 要打开的modal组件, 组件需继承BaseModal
   * @param props {Object|undefined} modal组件的props
   * @param on {Object|undefined} modal组件注册的事件回调
   */
  return function (component, props, on) {
    const div = document.createElement('div')
    document.body.appendChild(div)

    // 挂载dialog组件的vue实例，用于后续销毁
    let modalInstance

    // modal mixin，混入自定义modal组件中，用于销毁modal
    const modalMixin = {
      methods: {
        /**
         * 销毁组件
         */
        destroy() {
          setTimeout(() => {
            if (modalInstance && div.parentNode) {
              modalInstance.unmount()
              modalInstance = null
              div.parentNode.removeChild(div)
            }
          }, 200)
        },
      },
      watch: {
        /**
         * 监听modal组件的modalVisible属性，当modalVisible变为false时，销毁modal
         * @param newVal
         */
        modalVisible(newVal) {
          if (!newVal) {
            this.destroy()
          }
        },
      },
    }

    /**
     * 创建dialog组件实例并挂载到页面上
     *
     * @returns {Vue}
     */
    function render() {
      const app = createApp({
        render() {
          return h(ConfigProvider, { locale: zh_CN }, () => [h(component, { ...props, ...on })])
        },
      })
      // 初始化vue实例
      initFn(app)
      app.mixin(modalMixin).mount(div)
      return app
    }
    modalInstance = render()
  }
}
