import numeral from 'numeral'

/**
 * 自定义格式，支持格式化后显示单位，单位放在格式化字符串最后的中括号内
 * 例：numeral(1200).format('0,0.00[万元]') ==> 1,200.00万元
 **/
const regexpWithUnit = /\[(.+)\]$/
numeral.register('format', 'withUnit', {
  regexps: {
    format: regexpWithUnit,
  },
  format(value, format, roundingFunction) {
    const unit = regexpWithUnit.exec(format)[1]
    format = format.replace(regexpWithUnit, '')
    const output = numeral._.numberToFormat(value, format, roundingFunction)
    return output + unit
  },
})

/**
 * 格式化数字
 * @param number 原数字
 * @param format 格式，文档见http://numeraljs.com/
 * @param zeroReturn 如果数值为0返回值
 * @param nullReturn 如果数值为空返回值
 * @param maxThreshold 超过某值时时返回值 {value:最大值, returnString: '返回值'}
 */
export default function numberFormatter(number, format, zeroReturn, nullReturn, maxThreshold) {
  if (Number.isNaN(number)) {
    if (nullReturn) {
      return nullReturn
    } else {
      // eslint-disable-next-line no-param-reassign
      number = 0
    }
  }
  if (maxThreshold && number > maxThreshold.value) {
    return maxThreshold.returnString
  } else if (number === 0 && zeroReturn) {
    return zeroReturn
  } else if (number !== 0 && !number && nullReturn) {
    return nullReturn
  } else {
    return numeral(number).format(format)
  }
}
