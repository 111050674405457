<template>
  <div class="address-input">
    <area-picker v-model="area" placeholder="请选择" @blur="onFieldBlur" @change="onChange" />
    <a-input
      v-model:value="street"
      :maxlength="maxlength"
      placeholder="输入详细地址"
      style="margin-top: 8px"
      @blur="onFieldBlur"
      @change="onChange"
    />
  </div>
</template>

<script>
import { Form, Input } from 'ant-design-vue'
import AreaPicker from '../AreaPicker/index.vue'

export default {
  name: 'AddressInput',
  components: {
    AreaPicker,
    [Input.name]: Input,
  },
  props: {
    maxlength: {
      type: Number,
      default: 50,
    },
    modelValue: {
      type: Object,
      default() {
        return {
          provinceName: '',
          cityName: '',
          areaName: '',
          street: '',
        }
      },
    },
  },
  emits: ['update:modelValue', 'change'],
  setup() {
    const { onFieldChange, onFieldBlur } = Form.useInjectFormItemContext()
    return { onFieldChange, onFieldBlur }
  },
  data() {
    return {
      area: undefined,
      street: '',
    }
  },
  watch: {
    modelValue(newVal) {
      this.area = newVal.provinceName ? [newVal.provinceName, newVal.cityName, newVal.areaName] : undefined
      this.street = newVal.street
    },
  },
  mounted() {
    this.area = this.modelValue.provinceName
      ? [this.modelValue.provinceName, this.modelValue.cityName, this.modelValue.areaName]
      : undefined
    this.street = this.modelValue.street
  },
  methods: {
    onChange() {
      const ret = {
        provinceName: this.area?.[0],
        cityName: this.area?.[1],
        areaName: this.area?.[2],
        street: this.street,
      }
      this.$emit('update:modelValue', ret)
      this.$emit('change', ret)
      this.onFieldChange()
    },
  },
}
</script>

<style lang="less" scoped>
.divider {
  height: 0;
  border-top: solid 1px rgba(235, 237, 240, 1);
}
</style>
