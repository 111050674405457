<template>
  <div class="clearfix upload">
    <a-upload :file-list="fileList" list-type="picture-card" :before-upload="beforeUpload" @change="handleChange">
      <div v-if="fileList.length < 1">
        <upload-outlined />
        <div class="ant-upload-text">上传文件</div>
      </div>
    </a-upload>
  </div>
</template>

<script>
import { Form, message, Upload } from 'ant-design-vue'
import UploadOutlined from '@ant-design/icons-vue/UploadOutlined'
export default {
  name: 'UploadImage',
  components: {
    [Upload.name]: Upload,
    UploadOutlined,
  },
  props: {
    accept: {
      type: String,
      default: '.jpg,.jpeg,.png',
    },
    params: { type: Object, default: () => ({}) },
    type: { type: String, default: '' },
    size: {
      type: Number,
      default: 5,
    },
    modelValue: { type: String, default: '' },
    api: { type: Function, default: () => {} },
    wxApi: { type: Function, default: null }, // 微信素材api
  },
  emits: ['update:modelValue', 'change'],
  setup() {
    const { onFieldChange, onFieldBlur } = Form.useInjectFormItemContext()
    return { onFieldChange, onFieldBlur }
  },
  data() {
    return {
      fileList: [],
    }
  },

  watch: {
    modelValue(newValue) {
      if (newValue) {
        this.fileList = [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: newValue,
          },
        ]
      } else {
        this.fileList = []
      }
    },
  },

  methods: {
    beforeUpload(file) {
      if (this.accept) {
        const tmp = file.name.toLowerCase().split('.')
        const fileTypeValid = this.accept.toLowerCase().indexOf('.' + tmp.slice(tmp.length - 1)[0]) >= 0
        if (!fileTypeValid) {
          message.error(`只能上传${this.accept}类型的文件！`)
          return false
        }
      }
      const fileSizeValid = file.size / 1024 / 1024 < this.size
      if (!fileSizeValid) {
        message.error(`图片必须小于${this.size}MB！`)
        return false
      }

      if (this.wxApi) {
        // 微信素材先上传素材，在上传图片
        this.wxApi(file, this.params?.appId).then(materialId => {
          this.api(file, this.type).then(fileUrl => {
            this.$emit('update:modelValue', { fileUrl, materialId })
            this.$emit('change', { fileUrl, materialId })
            this.onFieldChange()
          })
        })
      } else {
        this.api(file, this.type).then(data => {
          this.$emit('update:modelValue', data)
          this.$emit('change', data)
          this.onFieldChange()
        })
      }
      return false
    },
    handleChange(event) {
      if (event.file && event.file.status === 'removed') {
        this.fileList = event.fileList
        this.$emit('update:modelValue', undefined)
        this.$emit('change', undefined)
        this.onFieldChange()
      }
    },
  },
}
</script>

<style lang="less" scoped></style>
