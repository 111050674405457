<template>
  <a-row class="pagination-wrapper">
    <a-col span="6">
      <slot></slot>
    </a-col>
    <a-col span="18" style="text-align: right">
      <a-pagination
        v-model:value="tmpPageNum"
        :size="size"
        :page-size-options="pageSizeOptions"
        :show-size-changer="hasPageSize"
        class="pagination"
        :total="total"
        :page-size="tmpPageSize"
        :show-quick-jumper="showQuickJumper"
        :show-total="showTotal"
        @change="onPageNumChange"
      />
    </a-col>
  </a-row>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    pageNum: {
      type: Number,
      default: 1,
    },
    showTotal: {
      type: Function,
      default: total => `共${total}条`,
    },
    showQuickJumper: {
      type: Boolean,
      default: true,
    },
    hasPageSize: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'small',
    },
    pageSizeOptions: {
      // Array | Boolean
      type: Array,
      default() {
        return ['10', '20', '50']
      },
    },
  },
  emits: ['change'],
  data() {
    return {
      tmpPageSize: this.pageSize || 10,
      tmpPageNum: this.pageNum || 1,
    }
  },
  watch: {
    pageNum(newVal) {
      this.tmpPageNum = newVal
    },
    pageSize(newVal) {
      this.tmpPageSize = newVal
    },
  },
  methods: {
    onPageNumChange(pageNum, pageSize) {
      this.tmpPageSize = pageSize
      this.$emit('change', pageNum, pageSize)
    },
  },
}
</script>

<style lang="less" scoped>
.pagination-wrapper {
  margin-top: 16px;

  .pagination {
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
