<template>
  <a-cascader
    v-model:value="tmpValue"
    class="category-select"
    :field-names="{ label: 'typeName', value: 'typeName', children: 'productTypeQueries' }"
    :options="items"
    :size="size"
    :get-popup-container="trigger => trigger.parentNode"
    :placeholder="placeholder"
    :show-search="showSearch"
    @change="onChange"
  />
</template>

<script>
import { Cascader, Form } from 'ant-design-vue'

export default {
  name: 'CategorySelect',
  components: {
    [Cascader.name]: Cascader,
  },
  props: {
    initData: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    showSearch: {
      type: Boolean,
      default() {
        return false
      },
    },
    size: {
      type: String,
      default() {
        return 'default'
      },
    },
  },
  emits: ['update:modelValue', 'change'],
  setup() {
    const { onFieldChange } = Form.useInjectFormItemContext()
    return { onFieldChange }
  },
  data() {
    return {
      items: this.initData,
      tmpValue: [],
    }
  },
  watch: {
    modelValue(val) {
      this.tmpValue = val
    },
    initData(val) {
      this.items = val
    },
  },
  mounted() {
    this.tmpValue = this.modelValue
  },
  methods: {
    onChange(value, selectedOptions) {
      this.$emit('update:modelValue', this.tmpValue)
      this.$emit('change', value, selectedOptions)
      this.onFieldChange()
    },
  },
}
</script>

<style lang="less" scoped></style>
