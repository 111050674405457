<template>
  <a-modal v-model:visible="modalVisible" :title="title" centered @ok="handleOk">
    <div v-if="!h3">
      <p>{{ info }}</p>
    </div>
    <div v-if="h3">
      <h3 v-if="h3">{{ h3 }}</h3>
      <p v-if="h3" class="lightgray">{{ info }}</p>
    </div>
    <template #footer>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleOk">确定</a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    h3: { type: String, default: '' },
    info: { type: String, default: '' },
  },

  emits: ['clickOk'],

  data() {
    return {
      modalVisible: true,
    }
  },

  methods: {
    handleCancel() {
      this.modalVisible = false
    },

    handleOk() {
      this.modalVisible = false
      this.$emit('clickOk')
    },
  },
}
</script>
