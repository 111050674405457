<template>
  <a-upload class="upload-button" :accept="accept" name="file" :show-upload-list="false" :before-upload="beforeUpload">
    <a-button :type="type">{{ text || '上传文件' }}</a-button>
  </a-upload>
</template>

<script>
import { Upload, Button, message } from 'ant-design-vue'

export default {
  name: 'UploadButton',
  components: {
    [Upload.name]: Upload,
    [Button.name]: Button,
  },
  props: {
    accept: { type: String, default: '' },
    type: { type: String, default: '' },
    params: { type: Object, default: null },
    size: {
      type: Number,
      default: 5,
    },
    text: { type: String, default: '' },
    api: {
      type: Function,
      default: () => {},
    },
  },
  emits: ['complete'],
  data() {
    return {
      uploading: false,
    }
  },
  methods: {
    beforeUpload(file) {
      if (this.accept) {
        const tmp = file.name.toLowerCase().split('.')
        const fileTypeValid = this.accept.toLowerCase().indexOf('.' + tmp.slice(tmp.length - 1)[0]) >= 0
        if (!fileTypeValid) {
          message.error(`只能上传${this.accept}类型文件！`)
          return false
        }
      }
      const fileSizeValid = file.size / 1024 / 1024 < this.size
      if (!fileSizeValid) {
        message.error(`文件必须小于${this.size}MB！`)
        return false
      }
      this.uploading = true
      if (this.params) {
        this.api(file, this.params)
          .then(data => {
            // debugger
            //  failure: 1, errorFile:
            this.uploading = false
            this.$emit('complete', data)
          })
          .catch(result => {
            // debugger
            this.uploading = false
            message.error(result.msg)
          })
      } else {
        this.api(file)
          .then(data => {
            // debugger
            this.uploading = false
            this.$emit('complete', data)
          })
          .catch(result => {
            // debugger
            this.uploading = false
            message.error(result.msg)
          })
      }
      return false
    },
  },
}
</script>

<style lang="less" scoped>
.upload-button {
  display: inline-block;
}
</style>
