// 动态注册该目录下以及所有子目录的所有.vue文件
const requireComponent = require.context('@/common/components', true, /\.vue$/)
const install = function (app) {
  //注册所有组件
  requireComponent.keys().forEach(filePath => {
    const config = requireComponent(filePath)
    const pathList = filePath.split('/')
    // 过滤掉components目录下的一级文件注册，如demo.vue
    if (pathList.length > 2) {
      const fileName = pathList[filePath.endsWith('index.vue') ? pathList.length - 2 : pathList.length - 1] // 如果组件名是index，取目录为组件名称
      let componentName = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')
      // 注册
      app.component(componentName, config.default || config)
    }
  })
}

export default {
  install,
}
