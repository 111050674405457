<template>
  <div class="category-picker">
    <a-select
      ref="select"
      v-model:value="selectedCategoryName"
      :default-value="selectedCategoryName"
      :placeholder="placeholder"
      :get-popup-container="trigger => trigger.parentNode"
      :dropdown-match-select-width="false"
      @focus="onFocus"
    >
      <template #dropdownRender>
        <div class="category-picker-dropdown">
          <div @mousedown="e => e.preventDefault()">
            {{ currentTab }}
            <a-tabs v-model:activeKey="currentTab" default-active-key="1">
              <a-tab-pane key="1" :tab="tmpSelection.selectedLevel1 ? tmpSelection.selectedLevel1.typeName : '大类'">
                <div>
                  <span
                    v-for="item in items"
                    :key="item.typeName"
                    class="category-item"
                    :class="{ selected: tmpSelection.selectedLevel1 === item, disabled: item.typeName === 'RX' }"
                    @click="onSelect(1, item)"
                    v-text="item.typeName"
                  ></span>
                </div>
              </a-tab-pane>
              <a-tab-pane
                key="2"
                :tab="tmpSelection.selectedLevel2 ? tmpSelection.selectedLevel2.typeName : '中类'"
                :disabled="!tmpSelection.selectedLevel1"
              >
                <div v-if="tmpSelection.selectedLevel1">
                  <span
                    v-for="item in tmpSelection.selectedLevel1.children"
                    :key="item.typeName"
                    class="category-item"
                    :class="{ selected: tmpSelection.selectedLevel2 === item }"
                    @click="onSelect(2, item)"
                    v-text="item.typeName"
                  ></span>
                </div>
              </a-tab-pane>
              <a-tab-pane
                key="3"
                :tab="tmpSelection.selectedLevel3 ? tmpSelection.selectedLevel3.typeName : '小类'"
                :disabled="!tmpSelection.selectedLevel2"
              >
                <div v-if="tmpSelection.selectedLevel2">
                  <span
                    v-for="item in tmpSelection.selectedLevel2.children"
                    :key="item.typeName"
                    class="category-item"
                    :class="{ selected: tmpSelection.selectedLevel3 === item }"
                    @click="onSelect(3, item)"
                    v-text="item.typeName"
                  ></span>
                </div>
              </a-tab-pane>
            </a-tabs>
            <div class="footer">
              <a-button type="primary" size="small" @click="onConfirm">确定</a-button>
            </div>
          </div>
        </div>
      </template>
    </a-select>
  </div>
</template>

<script>
import { Select, Tabs, Button, Form } from 'ant-design-vue'

export default {
  name: 'CategoryPicker',
  components: {
    [Select.name]: Select,
    [Tabs.name]: Tabs,
    [Tabs.TabPane.name]: Tabs.TabPane,
    [Button.name]: Button,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    placeholder: { type: String, default: '' },
    initData: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['change', 'update:modelValue'],
  setup() {
    const { onFieldChange } = Form.useInjectFormItemContext()
    return { onFieldChange }
  },
  data() {
    return {
      currentTab: '1',
      items: this.initData,
      selectedLevel1: undefined,
      selectedLevel2: undefined,
      selectedLevel3: undefined,
      tmpSelection: {
        selectedLevel1: undefined,
        selectedLevel2: undefined,
        selectedLevel3: undefined,
      },
    }
  },
  computed: {
    selectedCategoryName() {
      return (
        [this.selectedLevel1?.typeName, this.selectedLevel2?.typeName, this.selectedLevel3?.typeName]
          .filter(item => !!item)
          .join('-') || undefined
      )
    },
  },
  watch: {
    modelValue() {
      this.initSelection()
    },
  },
  mounted() {
    this.initSelection()
  },
  methods: {
    initSelection() {
      this.currentTab = '1'
      this.selectedLevel1 = this.items.find(item => item.typeName === this.modelValue?.[0])
      this.selectedLevel2 = this.selectedLevel1?.children.find(item => item.typeName === this.modelValue?.[1])
      this.selectedLevel3 = this.selectedLevel2?.children.find(item => item.typeName === this.modelValue?.[2])
    },
    onSelect(level, item) {
      // 暂时不能选择处方药
      if (item.typeName === 'RX') {
        return
      }
      if (this.tmpSelection[`selectedLevel${level}`] !== item) {
        this.tmpSelection[`selectedLevel${level}`] = item
        if (level === 1) {
          this.tmpSelection.selectedLevel2 = undefined
          this.tmpSelection.selectedLevel3 = undefined
        } else if (level === 2) {
          this.tmpSelection.selectedLevel3 = undefined
        }
      }
    },
    onFocus() {
      this.currentTab = '1'
      this.tmpSelection = {
        selectedLevel1: this.selectedLevel1,
        selectedLevel2: this.selectedLevel2,
        selectedLevel3: this.selectedLevel3,
      }
    },
    onConfirm() {
      this.selectedLevel1 = this.tmpSelection.selectedLevel1
      this.selectedLevel2 = this.tmpSelection.selectedLevel2
      this.selectedLevel3 = this.tmpSelection.selectedLevel3
      this.$refs.select.blur()
      this.$emit('update:modelValue', [
        this.selectedLevel1.typeName,
        this.selectedLevel2?.typeName,
        this.selectedLevel3?.typeName,
      ])
      this.$emit('change', [this.selectedLevel1.typeName, this.selectedLevel2?.typeName, this.selectedLevel3?.typeName])
      this.onFieldChange()
    },
  },
}
</script>

<style lang="less" scoped>
.category-picker-dropdown {
  width: 650px;
  .ant-tabs-tabpane {
    height: 200px;
    padding: 0 10px;
    overflow-y: auto;
    .category-item {
      display: inline-block;
      height: 32px;
      line-height: 32px;
      margin: 5px 10px;
      padding: 0 10px;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
      &.selected {
        color: rgba(255, 255, 255, 1);
        background: rgba(20, 100, 225, 1);
      }
      &.disabled {
        color: rgba(255, 255, 255, 1);
        background: rgb(169, 171, 173);
        cursor: not-allowed;
      }
    }
  }
  .footer {
    border-top: solid 1px #efefef;
    text-align: center;
    padding: 10px;
  }
}
</style>
