<template>
  <div>
    <a-breadcrumb>
      <a-breadcrumb-item v-for="(item, index) in routes" :key="item">
        <router-link v-if="index < routes.length - 1" :to="{ name: item.name }">
          {{ item.meta.title }}
        </router-link>
        <template v-else>{{ item.meta.title }}</template>
      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>

<script>
import { Breadcrumb } from 'ant-design-vue'
export default {
  name: 'Breadcrumb',
  components: {
    [Breadcrumb.name]: Breadcrumb,
  },
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
