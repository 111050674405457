<template>
  <router-link v-slot="{ navigate }" :to="to" custom :replace="replace">
    <a-button
      :type="type"
      :size="size"
      :class="className"
      :style="style"
      :disabled="disabled"
      role="link"
      @click="navigate"
    >
      <slot></slot>
    </a-button>
  </router-link>
</template>

<script>
import { Button } from 'ant-design-vue'
const toTypes = [String, Object]

export default {
  name: 'RouterLinkButton',
  components: {
    [Button.name]: Button,
  },
  props: {
    to: {
      type: toTypes,
      required: true,
    },
    replace: Boolean,
    type: { type: String, default: '' },
    size: { type: String, default: '' },
    className: { type: String, default: '' },
    style: { type: String, default: '' },
    disabled: Boolean,
  },
  data() {
    return {
      sizeMap: {
        small: 'sm',
        large: 'lg',
      },
      typeMap: {
        primary: 'primary',
        dashed: 'dashed',
        danger: 'danger',
        link: 'link',
      },
    }
  },
}
</script>

<style lang="less" scoped></style>
