<template>
  <div class="tw-border-[1px] tw-border-solid tw-border-[#ccc] tw-z-[99]">
    <Toolbar
      class="tw-border-solid tw-border-[#ccc] tw-border-b-[1px]"
      :editor="editorRef"
      style="border-top: 0; border-left: 0; border-right: 0"
      :default-config="toolbarConfig"
      :mode="mode"
    />
    <Editor
      v-model="valueHtml"
      class="overflow-hidden"
      :style="{ height: height + 'px', width: width + 'px' }"
      :default-config="editorConfig"
      :mode="mode"
      @on-created="handleCreated"
      @on-change="handleChange"
      @on-focus="handleFocus"
      @on-blur="handleBlur"
    />
  </div>
</template>

<script setup>
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import { onBeforeUnmount, ref, shallowRef, watch } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { message } from 'ant-design-vue'

const props = defineProps({
  modelValue: {
    // 编辑框的值（html格式），单纯文本格式可以通过实例的getText()方法获取
    type: String,
    default: '',
  },
  placeholder: {
    //暗文
    type: String,
    default: '',
  },
  mode: {
    // 编辑器模式，default：默认模式，包括所有功能，simple：简介模式，仅有部分功能
    type: String,
    default: 'default',
  },
  width: {
    type: Number,
    default: 930,
  },
  height: {
    // 编辑框部分的高度，不包括工具栏
    type: Number,
    default: 300,
  },
  toolbarConfig: {
    // 工具栏配置
    type: Object,
    default: () => ({}),
  },
  editorConfig: {
    // 编辑器配置
    type: Object,
    default: () => ({}),
  },
  accept: {
    // 设置上传的图片格式
    type: String,
    default: '.jpg,.jpeg,.png',
  },
  imageType: {
    type: String,
    default: 'product',
  },
  imageUploadApi: {
    type: Function,
    default: () => {},
  },
})

// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef()

// 内容 HTML,判断是否未html格式，如果不是自动加上p标签，避免文本渲染失败
const valueHtml = ref(/^<p>.*<\/p>$/.test(props.modelValue) ? props.modelValue : '<p>' + props.modelValue + '</p>')

// 监听值变化
watch(props, () => {
  if (valueHtml.value !== props.modelValue) {
    valueHtml.value = /^<p>.*<\/p>$/.test(props.modelValue) ? props.modelValue : '<p>' + props.modelValue + '</p>'
  }
})

const toolbarConfig = {
  excludeKeys: [
    // 不展示的工具
    'undo', // 撤销
    'redo', // 重做
    'emotion', // 表情
  ],
  ...props.toolbarConfig,
}

let editorConfig = { ...props.editorConfig }
// 是否配置了上传图片
if (
  props?.toolbarConfig?.toolbarKeys?.find(item => {
    return item === 'uploadImage'
  })
) {
  editorConfig = { ...props.editorConfig, placeholder: props.placeholder, MENU_CONF: { uploadImage: {} } }
  editorConfig.MENU_CONF['uploadImage'] = {
    // 插入一张图片，调用通用上传图片接口
    customUpload(file, insertFn) {
      onFileChange(file, insertFn)
      console.log('customUpload', file)
      console.log('insertFn', insertFn)
    },
  }
}

const onFileChange = (file, insertFn) => {
  if (!file) {
    return
  }
  if (props.accept) {
    const tmp = file.name.toLowerCase().split('.')
    const fileTypeValid = props.accept.toLowerCase().indexOf('.' + tmp.slice(tmp.length - 1)[0]) >= 0
    if (!fileTypeValid) {
      message.error('只能上传jpg/png文件！')
      return
    }
  }
  const fileSizeValid = file.size / 1024 / 1024 < 5
  if (!fileSizeValid) {
    message.error('图片必须小于5MB！')
    return
  }
  props.imageUploadApi(file, props.imageType).then(data => {
    if (data) {
      insertFn(data, file.name, data) // 插入到文本框
    }
  })
}

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value
  if (editor == null) return
  editor.destroy()
})

// created：编辑器创建回调，返回编辑器实例
const emit = defineEmits(['update:modelValue', 'created', 'blur', 'focus'])

const handleCreated = editor => {
  editorRef.value = editor // 记录 editor 实例，重要！
  emit('created', editorRef.value)
  editorRef.value.focus()
}

const handleChange = editor => {
  // console.log(JSON.stringify(editor.getAllMenuKeys())) // 输出工具栏所有配置项
  emit('update:modelValue', editor.getHtml())
}

const handleBlur = () => {
  emit('blur')
}

const handleFocus = () => {
  emit('focus')
}
</script>
<style lang="less" scoped>
:deep(ol) {
  list-style: auto;
}

:deep(ul) {
  list-style: disc;
}
</style>
