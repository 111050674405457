<template>
  <router-view v-slot="{ Component, route }">
    <keep-alive :include="include">
      <component :is="wrap(route.query.STACK_KEY, Component)" :key="route.query.STACK_KEY" />
    </keep-alive>
  </router-view>
</template>
<script>
import { h } from 'vue'

export default {
  name: 'KeepAliveRouterView',
  data: function () {
    return {
      // 自定义name的壳的集合
      wrapperMap: new Map(),
      // 缓存组件名称数据
      include: [],
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        if (!to.name) return
        const stackKey = to.query.STACK_KEY
        const parent = to.matched.at(-2)
        // 不同菜单之间菜单跳转（跳转到某个菜单首页，且未缓存过该页面），清除所有缓存的页面
        if (parent?.meta.isMenu && parent?.redirect.name === to.name && !this.include.includes(stackKey)) {
          this.include = []
        }
        if (!this.include.includes(stackKey)) {
          // 如果没加入这个路由记录，则加入路由历史记录
          this.include.push(stackKey)
        } else {
          // 从最后一个缓存的页面返回倒数第二个缓存的页面，是返回操作，清除最后一个缓存的页面
          if (stackKey === this.include.at(-2) && from.query.STACK_KEY === this.include.at(-1)) {
            this.include.length = this.include.length - 1
          }
        }
        console.log(this.include)
      },
      immediate: true,
    },
  },
  methods: {
    // 为keep-alive里的component接收的组件包上一层自定义name的壳.
    wrap(stackKey, component) {
      let wrapper
      // 重点就是这里，这个组件的名字是完全可控的，
      // 只要自己写好逻辑，每次能找到对应的外壳组件就行，完全可以写成任何自己想要的名字.
      // 这就能配合 keep-alive 的 include 属性可控地操作缓存.
      const wrapperName = stackKey
      if (this.wrapperMap.has(wrapperName)) {
        wrapper = this.wrapperMap.get(wrapperName)
      } else {
        wrapper = h({
          name: wrapperName,
          render() {
            return h(component)
          },
        })
        this.wrapperMap.set(wrapperName, wrapper)
      }
      return wrapper
    },
  },
}
</script>
