<template>
  <a-modal
    :visible="modelValue"
    :mask-closable="false"
    :centered="true"
    title="导入结果"
    class="edit-store-position-modal"
    @cancel="hide"
  >
    <a-row>
      <a-col :span="24">
        <div v-if="importResult.failure !== 0">
          您共导入{{ importResult.total }}条数据，其中{{
            importResult.failure
          }}条失败。点击下载导入失败表，修改后上传即可。
        </div>
        <span v-else>成功导入{{ importResult.total }}条数据。</span>
      </a-col>
      <a-col v-if="importResult.errorTable" class="margin-top-10" :span="24">
        <a-table
          :row-key="importResult.errorTable.columnKey"
          :columns="importResult.errorTable.errorTableColumns"
          :data-source="importResult.errorTable.data"
          :scroll="{ x: importResult.errorTable.width || null, y: importResult.errorTable.height || 250 }"
          :pagination="false"
        >
        </a-table>
      </a-col>
    </a-row>
    <template #footer>
      <a-row v-if="!importResult.errorFile">
        <a-col :span="24">
          <a-button type="primary" @click="hide">确定</a-button>
        </a-col>
      </a-row>
      <a-row v-if="importResult.errorFile">
        <a-col :span="24">
          <a-button type="primary" @click="downloadErrorFile">下载失败数据</a-button>
          <a-button @click="hide">关闭</a-button>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script>
import { Modal, Row, Col, Button, Table } from 'ant-design-vue'

export default {
  name: 'ImportResultModal',
  components: {
    [Modal.name]: Modal,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [Table.name]: Table,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    importResult: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update:modelValue'],
  methods: {
    hide() {
      this.$emit('update:modelValue', false)
    },
    downloadErrorFile() {
      window.open(this.importResult.errorFile)
    },
  },
}
</script>
